import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/home';
import Footer from './widgets/footer';
import Navbar from './widgets/navbar';
import Project from "./pages/project";
import CV from "./pages/curriculum-vitae";
import Inspiration from "./pages/inspiration";
import About from "./pages/about";
import Contact from "./pages/contact";
import Imprint from "./pages/imprint";
import Projects from "./pages/projects";

export default function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/project/:id" element={<Project />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/curriculum-vitae" element={<CV />} />
          <Route path="/inspiration" element={<Inspiration />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/imprint" element={<Imprint />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

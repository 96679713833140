import { Link } from "react-router-dom";
import "./navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

export default function Navbar() {

  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust width as per your mobile breakpoint
    };
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    isMobile
    ? <div className="nav-bar">
        <Link to="/home" className={`name ${open ? "t-white" : "t-black"}`}> NICLAS REIF </Link>

        <div className="icon-box" onClick={() => setOpen(prev => !prev)}>
          <FontAwesomeIcon icon={open ? faTimes : faBars} />
        </div>
        <div className={`nav-content ${open ? "open" : ""}`}>
          <Link to={"/curriculum-vitae"} id="cv" onClick={() => setOpen(false)}>CV</Link>
          <Link to={"/about"} id="about" onClick={() => setOpen(false)}>About Me</Link>
          <Link to={"/projects"} id="projects" onClick={() => setOpen(false)}>Projects</Link>
          <Link to={"/inspiration"} id="inspiration" onClick={() => setOpen(false)}>Inspiration</Link>
          <Link to={"/contact"} id="contact" onClick={() => setOpen(false)}>
            Contact
          </Link>
        </div>
      </div>
    : <div className="nav-bar">
        <Link to="/home" className="name t-black"> NICLAS REIF </Link>
        <div className="flex-row gap-3">
            <Link to={"/curriculum-vitae"} id="cv">CV</Link>
            <Link to={"/about"} id="about">About Me</Link>
            <Link to={"/projects"} id="projects">Projects</Link>
            <Link to={"/inspiration"} id="inspiration">Inspiration</Link>
          </div>
          <div className="nav-content">
            <Link to={"/contact"} id="contact" style={{ marginLeft: "150px" }}>
              Contact
            </Link>
          </div>
      </div>
  );
}
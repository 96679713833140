import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

export default function Imprint() {

    return(
        <div className="main">
            <Helmet>
                <title>Imprint</title>
            </Helmet>
            <div className="flex-col gap-3">
                <div className="flex-col gap-1 items-center">
                    <div>All contents of this website were created and designed by Niclas Reif.</div>
                    <div>Below a list of assets that I used for improved illustration of the contents </div>
                </div>
                <div className="flex-col gap-1">
                    <div className="large font-semibold">Icons</div>
                    <div className="regular">
                        <div className="flex-row justify-between">Germany flag <a href="https://www.flaticon.com/de/kostenlose-icons/deutschland" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Spain flag <a href="https://www.flaticon.com/de/kostenloses-icon/spanien_330557?term=spanien&page=1&position=4&origin=search&related_id=330557" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">UK flag <a href="https://www.flaticon.com/de/kostenloses-icon/vereinigtes-konigreich_555417?term=uk&page=1&position=5&origin=search&related_id=555417" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">France flag <a href="https://www.flaticon.com/de/kostenloses-icon/frankreich_330490?term=frankreich+flagge&page=1&position=3&origin=search&related_id=330490" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">CSS icon <a href="https://www.flaticon.com/de/kostenloses-icon/css-3_732190?term=css&page=1&position=2&origin=search&related_id=732190" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Python icon <a href="https://www.flaticon.com/de/kostenloses-icon/python_5968350?term=python&page=1&position=4&origin=search&related_id=5968350" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">JavaScript icon <a href="https://www.flaticon.com/de/kostenloses-icon/javascript_919828?term=javascript&page=1&position=1&origin=search&related_id=919828" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Figma icon <a href="https://www.flaticon.com/de/kostenloses-icon/figma_5968705?term=figma+logo&page=1&position=1&origin=search&related_id=5968705" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Adobe icon <a href="https://www.flaticon.com/de/kostenloses-icon/kreative-wolke_731965?term=adobe&page=1&position=7&origin=search&related_id=731965" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Excel icon <a href="https://www.flaticon.com/de/kostenloses-icon/excel_732220?term=excel&page=1&position=1&origin=search&related_id=732220" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Powerpoint icon <a href="https://www.flaticon.com/de/kostenloses-icon/power-point_888874?term=powerpoint&page=1&position=3&origin=search&related_id=888874" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Word icon <a href="https://www.flaticon.com/de/kostenloses-icon/wort_888883?term=word&page=1&position=2&origin=search&related_id=888883" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Blender icon <a href="https://www.blender.org/about/logo/" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">+ other icons <a href="https://fontawesome.com/" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                    </div>
                </div>
                <div className="flex-col gap-1">
                    <div className="large font-semibold">Images</div>
                    <div className="regular">
                        <div className="flex-row justify-between">Muhammad Yunus <a href="https://rfkhumanrights.org/person/muhammad-yunus-2/" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Norman Foster <a href="https://www.rocketfm.org.uk/norman-foster/" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Jony Ive <a href="https://sfg.media/en/magazine/the-problem-is-the-only-clear-thing-at-the-beginning-of-work-jony-ive/" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Frank Ocean <a href="https://www.dazeddigital.com/music/article/46480/1/frank-ocean-defends-his-prep-hiv-new-york-club-night-against-critics" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Steve Jobs <a href="https://t3n.de/news/steve-jobs-bewerbung-vesteigerung-chatterfields-1358200/" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Michael Jordan <a href="https://www.amazon.de/Michael-Jordan-6-rings-English-ebook/dp/B00YBAT4A2" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Peter Thiel <a href="https://www.bloomberg.com/news/features/2021-09-15/peter-thiel-gamed-silicon-valley-tech-trump-taxes-and-politics" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">James Turrell <a href="https://artinterview.com/en/interviews/james-turrell/" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Jean-Michel Basquiat <a href="https://www.guggenheim-bilbao.eus/en/the-collection/artists/jean-michel-basquiat" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Bill Walsh<a href="https://www.espn.com/nfl/story/_/page/greatestcoach2/greatest-coaches-nfl-history-bill-walsh" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Kobe Bryant<a href="https://www.cnbc.com/2019/08/23/lakers-trainer-gary-vitti-on-what-kobe-bryant-taught-him.html" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Current Look - Viktoriabad<a href="https://www.baunetz-campus.de/news/viva-viktoria-entwuerfe-fuer-die-nachnutzung-des-viktoriabads-8238841" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                        <div className="flex-row justify-between">Current Info Display - Aachen<a href="https://www.aachen.de/DE/stadt_buerger/verkehr_strasse/verkehrssicherheit/01_Aktionsplan_Verkehrssicherheit/03_Oeffentlichkeitsarbeit/20190601_ASEAG-Fahrgastmonitore.html" className="green flex-row items-center gap-1">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import james_turrell from "../images/moguls/james-turrell.JPG";
import peter_thiel from "../images/moguls/peter_thiel.webp";
import norman_foster from "../images/moguls/norman_foster.jpeg";
import muhammad_yunus from "../images/moguls/muhammad_yunus.jpg";
import jony_ive from "../images/moguls/jony_ive.jpg";
import steve_jobs from "../images/moguls/steve_jobs.jpg";
import frank_ocean from "../images/moguls/frank_ocean.jpg";
import michael_jordan from "../images/moguls/michael_jordan.png";
import basquiat from "../images/moguls/basquiat2.jpg";
import bill_walsh from "../images/moguls/bill_walsh.png";
import kobe_bryant from "../images/moguls/kobe_bryant2.png";

import "./inspiration.css";
import "../index.css";
import { Helmet } from "react-helmet";

export default function Inspiration() {

    const moguls = [
        { name: "Muhammad Yunus", occupation: "Social Entrepreneur", description: "I attended a panel talk of him by accident and was amazed by his story. It inspired me to become socially entrepreneurial one day as well.", image: muhammad_yunus },
        { name: "Norman Foster", occupation: "Architect", description: "His style is unique as he tries to make his buildings as sustainable as possible. This causes a level of innovation like nowhere else.", image: norman_foster },
        { name: "Jony Ive", occupation: "Product Designer", description: "His discretion and attention to detail is beautiful. The amount of revolutionary products he created is unfathomable.", image: jony_ive },
        { name: "Frank Ocean", occupation: "Musician", description: "To me, he embodies perfectionism and love for his work. He didn’t release a lot of music but the little he released is timeless.", image: frank_ocean },
        { name: "Steve Jobs", occupation: "Visionary", description: "He was a true visionary who saw what others could not. I learned a lot from his extreme focus on simplicity in design.", image: steve_jobs },
        { name: "Michael Jordan", occupation: "Athlete", description: "His intensity and dominance is very impressive to me. He was not only the greatest player ever but also a charismatic personality.", image: michael_jordan },
        { name: "Peter Thiel", occupation: "Entrepreneur", description: "To me, only very few people understand the notion of startups better than him. I come back to his book 'Zero to One' a lot.", image: peter_thiel },
        { name: "James Turrell", occupation: "Light Architect", description: "I never knew what is possible with lighting until I saw his work. The spaces, places and exhibitions he creates are unique.", image: james_turrell },
        { name: "Jean-Michel Basquiat", occupation: "Artist", description: "Him painting on used doors describes overcoming obstacles to reach a goal for me. Also he was know for always being curious.", image: basquiat },
        { name: "Bill Walsh", occupation: "Coach", description: "What fascinated me the most was his approach that every tiny aspect of the franchise had to be executed perfectly for it to become the best.", image: bill_walsh },
        { name: "Kobe Bryant", occupation: "Athlete", description: "His obsession, dedication and willingness to give it everything at everything at all times is something to admire and strive for.", image: kobe_bryant }
    ];

    return (
        <div className="main">
            <Helmet>
                <title>Inspiration</title>
            </Helmet>
            <div className="flex-col items-center">
                <div className="massive font-semibold">The Moguls</div>
                <div>I draw inspiration from a lot of people. This is a very short list of some of the people that I spend more time reading into and that inspire me.</div>
            </div>
            <div className="mogul-row">
                {moguls.map((mogul, index) => (
                    <div key={index} className="mogul">
                        <img alt={mogul.name} src={mogul.image} className="mogul-picture" />
                        <div className="mogul-info">
                            <div className="mogul-name">{mogul.name}</div>
                            <div className="mogul-occupation">{mogul.occupation}</div>
                            <div className="mogul-description">{mogul.description}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="otherInspo">Others: Renell Medrano, Sampha Lahai Sisay, Kendrick Lamar Duckworth, Cole Bennett, Casey Neistat, Emma Chamberlain, Beau Miles, Olaolu Slawn, Luca Fersko, Charlotte Taylor + friends & family</div>
        </div>
    );
}
import React, { createContext, useContext } from 'react';

// images for Ryanair Presentation
import img1 from "../images/Ryanair/ryanair_presentation/r1.jpg";
import img2 from "../images/Ryanair/ryanair_presentation/r2.jpg";
import img3 from "../images/Ryanair/ryanair_presentation/r3.jpg";
import img4 from "../images/Ryanair/ryanair_presentation/r4.jpg";
import img5 from "../images/Ryanair/ryanair_presentation/r5.jpg";
import img6 from "../images/Ryanair/ryanair_presentation/r6.jpg";
import img7 from "../images/Ryanair/ryanair_presentation/r7.jpg";
import img8 from "../images/Ryanair/ryanair_presentation/r8.jpg";
import img9 from "../images/Ryanair/ryanair_presentation/r9.jpg";
import img10 from "../images/Ryanair/ryanair_presentation/r10.jpg";
import img11 from "../images/Ryanair/ryanair_presentation/r11.jpg";
import img12 from "../images/Ryanair/ryanair_presentation/r12.jpg";
import img13 from "../images/Ryanair/ryanair_presentation/r13.jpg";
import img14 from "../images/Ryanair/ryanair_presentation/r14.jpg";
import img15 from "../images/Ryanair/ryanair_presentation/r15.jpg";
import img16 from "../images/Ryanair/ryanair_presentation/r16.jpg";
import img17 from "../images/Ryanair/ryanair_presentation/r17.jpg";
import img18 from "../images/Ryanair/ryanair_presentation/r18.jpg";
import img19 from "../images/Ryanair/ryanair_presentation/r19.jpg";
import img20 from "../images/Ryanair/ryanair_presentation/r20.jpg";
import img21 from "../images/Ryanair/ryanair_presentation/r21.jpg";

// images for Ryanair BTS
import img22 from "../images/Ryanair/bts/card_finalslide.jpg";
import img23 from "../images/Ryanair/bts/designing_colors.jpg";
import img24 from "../images/Ryanair/bts/first_sketch.jpg";
import img25 from "../images/Ryanair/bts/layout_test.JPG";
import img26 from "../images/Ryanair/bts/notes_sketch.JPG";
import img27 from "../images/Ryanair/bts/ryanair_draftlayout.JPG";
import img28 from "../images/Ryanair/bts/second_sketch_empty.JPG";
import img29 from "../images/Ryanair/bts/workplace.png";
import img30 from "../images/Ryanair/bts/reenacting.jpg";

// images for Viktoriabad BTS
import img31 from "../images/Viktoriabad/großesBad_hinten.jpeg";
import img32 from "../images/Viktoriabad/großesBad_vorne.jpeg";
import img33 from "../images/Viktoriabad/kleinesBadHinten.jpeg";
import img34 from "../images/Viktoriabad/kleinesBadSeite.jpeg";
import img35 from "../images/Viktoriabad/kleinesBadVorne.jpeg";
import img36 from "../images/Viktoriabad/kleinZuGroß.jpeg";
import img37 from "../images/Viktoriabad/spinde.jpeg";
import img38 from "../images/Viktoriabad/spinde2.jpeg";
import img39 from "../images/Viktoriabad/render4_club.png";
import imgOffice from "../images/Viktoriabad/office_render.jpg";
import imgProgramm from "../images/Viktoriabad/example_programm_victoria.png";
import imgBar from "../images/Viktoriabad/main_render.png";

// images for aachen
import img40 from "../images/Aachen/framed_1.png";
import img40A from "../images/Aachen/overview4.png";
import img41 from "../images/Aachen/framed_2.png";
import img41A from "../images/Aachen/sketches_aachen.jpg";
import img42 from "../images/Aachen/framed_3.png";
import img42A from "../images/Aachen/inspo_aachen.png";
import img43 from "../images/Aachen/framed_4.png";
import img43A from "../images/Aachen/current_aachen.jpg";

// images for rolef
import img44 from "../images/rolef_render.png";
import imgSlide1 from "../images/Rolef/rolef_slide1.jpg";
import imgSlide2 from "../images/Rolef/rolef_slide2.jpg";
import imgSlide3 from "../images/Rolef/rolef_slide3.jpg";
import imgSlide4 from "../images/Rolef/rolef_slide4.jpg";
import imgSlide5 from "../images/Rolef/rolef_slide5.jpg";
import imgSlide6 from "../images/Rolef/rolef_slide6.jpg";
import imgSlide7 from "../images/Rolef/rolef_slide7.jpg";
import imgSlide8 from "../images/Rolef/rolef_slide8.jpg";
import imgSlide9 from "../images/Rolef/rolef_slide9.jpg";
import imgSlide10 from "../images/Rolef/rolef_slide10.jpg";
import imgSlide11 from "../images/Rolef/rolef_slide11.jpg";
import imgSlide12 from "../images/Rolef/rolef_slide12.jpg";
import imgSlide13 from "../images/Rolef/rolef_slide13.jpg";
import imgwebsite from "../images/Rolef/screenshot_reparatur.png";


// images for elgio
import img46 from "../images/Elgio/big_banner.png";
import webapp1 from "../images/Elgio/products/article_list.png";
import webapp2 from "../images/Elgio/products/calender_list.png";
import webapp3 from "../images/Elgio/products/calender_map.png";
import webapp4 from "../images/Elgio/products/checkout.png";
import webapp5 from "../images/Elgio/products/club_overview.png";
import webapp6 from "../images/Elgio/products/event_view.png";
import webapp7 from "../images/Elgio/products/upcoming_events.png";
import dashboard1 from "../images/Elgio/products/ticketing_anlegen.png";
import dashboard2 from "../images/Elgio/products/dashboard_overview.png";
import dashboard3 from "../images/Elgio/products/event_creation.png";
import dashboard4 from "../images/Elgio/products/event_management.png";
import dashboard5 from "../images/Elgio/products/marketing.png";
import dashboard6 from "../images/Elgio/products/profil_overview.png";
import dashboard7 from "../images/Elgio/products/settings.png";
import dashboard8 from "../images/Elgio/products/statistics.png";
import dashboard9 from "../images/Elgio/products/ticketingtab_dashboard.png";


// pdfs
import myPDF from '../pdfs/Ryanair_CardDesign_Presentation_OFF.pdf';
import consultingPitch from "../pdfs/Rolef_Präsi_pdf.pdf";
import renders from "../pdfs/Renders.zip";
import { faArrowDown, faGlobe } from '@fortawesome/free-solid-svg-icons';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const projects = [
        {
            id: "elgio",
            title_short: "ELGIO",
            title_long: "Software products for the event market",
            backgroundIMG: img46,
            sections: [
                {
                    title: "Overview",
                    content: "The idea of our startup ELGIO was born out of the wish for a centralized place to find Partys and all its information. When we started working on the idea in January 2020, information was spread across multiple forms of media (Instagram, Facebook, Print ...) and was often not even provided. Our inital idea was to create an application to display the events and a data scraper that collected information from targeted sites. The more we worked on it, the more we realised that the organizers were still operating very analog and therefore struggled to provide information digitally. In the next 4 years we built an extensive plattform for people to browse and find events as well as a dashboard for organizers to manage all aspects of their events (ticketing, marketing, analytics ...).",
                },
                // {
                //     title: "Pitch Deck",
                //     images: [
                //         { image: img1, subtitle: "Title Slide"},
                //     ],
                // },
                {
                    title: "Building during Covid-19",
                    content: "Unluckly we started building ELGIO in January 2020 just 3 months before the whole world went into Lockdown. being a first time founder and suddenly your whole industry collapses was strange. Opposite of being skeptical if our product will ever be needed again, we quickly changed towards the mentality that the desire for events will be stronger than ever and therefore the demand for our product as well. It took the industry until Summer 2021 until the first events under strict testing restrictions could start again. We launched our product in September 2021 and experienced 2 exciting months before the next lockdown shut the industry for 6 months down.",
                },
                {
                    title: "Product 1: Webapp",
                    content: "The Webapp is the product facing people that browse for events. Here they can find events and clubs in every major city in Germany. They can change the view from a list to a map and filter events based on different criteria (date, genre, type etc.). Each event, club and artist displays all the given information in the most appealing and structured way possible.",
                    images: [
                        {image: webapp2, subtitle: "List View of upcoming events"},
                        {image: webapp3, subtitle: "Map View of events"},
                        {image: webapp4, subtitle: "Ticket selection and purchase process"},
                        {image: webapp5, subtitle: "Overview of Clubs"},
                        {image: webapp6, subtitle: "Event Page"},
                        {image: webapp7, subtitle: "Upcoming Events of Clubs Preview"},
                        {image: webapp1, subtitle: "Article Page"},
                    ],
                },
                {
                    title: "Product 2: Dashboard",
                    content: "The Dashboard is the product facing organizers. On the Dashboard organizers can manage their events and do all core tasks related to them (marketing, ticketing, statistics and more. The dashboard is kept simple with 5 main navigation options and only a selected amount of functions.",
                    images: [
                        {image: dashboard2, subtitle: "Dashboard Home Page"},
                        {image: dashboard1, subtitle: "Create Ticketing with live overview on the right"},
                        {image: dashboard3, subtitle: "Create an event"},
                        {image: dashboard4, subtitle: "Overview of events and drafts"},
                        {image: dashboard5, subtitle: "Overview of events with marketing enabled"},
                        {image: dashboard6, subtitle: "Profile information overview"},
                        {image: dashboard7, subtitle: "Settings"},
                        {image: dashboard8, subtitle: "Statistics"},
                        {image: dashboard9, subtitle: "Ticketing Section of an event"},
                    ],
                },
                {
                    title: "Learnings about building products",
                    content: "When building a start-up you constantly make mistakes and learn new things. Here are some thoughts on major learnings we did: 1) Focus on one thing and do it incredibly better than anyone else. Instead of being the mediocore something of everything, be the best something in one specific thing. 2) Do not guess what the client wants, talk to him. We often built features and services that completely flopped because we thought that people needed them without actually talking to the client first.",
                },
                
                {
                    title: "Links & Downloads",
                    elements: [
                        // {
                        //     name: "ELGIO Pitch",
                        //     link: myPDF,
                        //     icon: faArrowDown,
                        // },
                        {
                            name: "Website & Webapp",
                            link: "https://elgio.de",
                            icon: faGlobe,
                        },
                        {
                            name: "Dashboard",
                            link: "https://dashboard.elgio.de",
                            icon: faGlobe,
                        }
                    ],
                },
            ]
        },
        {
            id: "ryanair",
            title_short: "Ryanair",
            title_long: "Re-design of the Ryanair Airplane Safety Card",
            backgroundIMG: img29,
            sections: [
                {
                    content: "Disclaimer: This project is self-entitled and is not (yet) connected to Ryanair",
                },
                {
                    title: "Overview",
                    content: "The idea for redesigning the safety card was born when I was on a Ryanair flight to Athens. As one maybe know, the card is unconventionally a sticker that is placed on eye-level height. Therefore starring at the card is inevitable. At this point the card was already annoying me because it chaotic layout and many visual flaws. So I started writing down all the things that bothered me, designed a rough new layout and measured the proportions with the length of my pen. The idea stuck to me so I called a friend and together with her we completely re-designed the card.",
                },
                {
                    title: "Presentation",
                    images: [
                        { image: img1, subtitle: "Title Slide"},
                        { image: img2, subtitle: "Intro Slide"},
                        { image: img3, subtitle: "Visualisation of current issues with the card"},
                        { image: img4, subtitle: "Summary of current issues"},
                        { image: img5, subtitle: "Three reasons why it is relevant for the airline"},
                        { image: img6, subtitle: "Objectives for the new design"},
                        { image: img7, subtitle: "Intro Slide Creation Process"},
                        { image: img8, subtitle: "Looking at current processes"},
                        { image: img9, subtitle: "Breakdown of visual guidance through current card"},
                        { image: img10, subtitle: "Visualize how the new layout improves readability"},
                        { image: img11, subtitle: "Showcase alignment issues with current card"},
                        { image: img12, subtitle: "Visualize how the new layout improves relation between dispictions"},
                        { image: img13, subtitle: "Highlight of a new addition to the card"},
                        { image: img14, subtitle: "Highlight of an improved element on the card"},
                        { image: img15, subtitle: "Highlight of an improved element on the card 2"},
                        { image: img16, subtitle: "Final Design Slide"},
                        { image: img17, subtitle: "The finished new safety card"},
                        { image: img18, subtitle: "Two additional ideas to make the card more unique"},
                        { image: img19, subtitle: "Rough cost estimate for implementation"},
                        { image: img20, subtitle: "Advantages of the new vs. current design"},
                        { image: img21, subtitle: "Final Slide"}
                    ],
                },
                {
                    title: "Design Process",
                    content: "We started by looking at ca. 50 different safety cards of other airlines and wrote down what we liked and did not like. We then broke down the card into all its different dispicted processes and checked if they were A) too long B) not clear C) repetitive or D) unnecessary. This resulted in us knowing how much space we had (as it is a 1-sided sticker) and what we need to display on this restricted space. We then brainstormed various layout options with different gap sizes and checked them for readability. As we agreed on one layout, we then knew exactly the dimensions of each box and what process is displayed in it. Paula then started sketching each box and I created a list with useful hints and inspiration by other airlines. Finally we started colouring the card. Similar to the rest of the card, we tried to use as little (colors) as possible. We often looked at the card and reduced every image to its very core essentials.",
                },
                {
                    title: "Challenges",
                    content: "As Ryanair did not commission us, we had no access to other informations etc. For instance we had to call the customer service team to get information about the childrens vest. We also had to personally model and re-enact the persons on the dispictions as Paula found it difficult to get the dimensions right. The man and the woman are basically Paula's sister and I.",
                },
                {
                    title: "Behind the Scenes",
                    images: [
                        { image: img29, subtitle: "Our collaborative work environment to share progress, notes, ideas and inspiration"},
                        { image: img24, subtitle: "Very first sketch on the airplane"},
                        { image: img28, subtitle: "First proper layout design with pen and paper"},
                        { image: img26, subtitle: "Notes and Markings on current card"},
                        { image: img25, subtitle: "First digital layout ideas"},
                        { image: img27, subtitle: "Testing our layout by filling it with illustrations from old card"},
                        { image: img30, subtitle: "Reenacting positions for easier illustrations"},
                        { image: img23, subtitle: "Creating the color palatte"},
                        { image: img22, subtitle: "Designing final slide of presentation"},
                    ],
                },
                {
                    title: "Contacting Ryanair",
                    content: "As our goal was also to present Ryanair the card, we created a pitch deck. The deck focuses on one side on the basics of a pitch deck (problem-, solution-, costs-, further ideas-, advantages slide) and also on the creation of the card in order for someone to fully understand what changed and why. After having talked to a friend that works in a airspace consulting agency, he gave us feedback on the deck and reached out to Ryanair's CEO. Unlucky for us at that time Boeing's airplanes started to report more and more problems and e.g. the delivery of 60 expected Ryanair planes postponed. This caused the whole company to focus on maintaining their reputation and solving the economical issues rather than sitting down with us. We are now still reaching out to Ryanair and try to pitch them our concept.",
                },
                {
                    title: "Links",
                    elements: [
                        {
                            name: "PDF Presentation",
                            link: myPDF,
                            icon: faArrowDown,
                        },
                    ],
                },
            ]
        },
        {
            id: "rolef",
            title_short: "Rolef",
            title_long: "New Website, store layout and consulting for locksmith",
            backgroundIMG: img44,
            sections: [
                {
                    title: "Overview",
                    content: "With a new employee coming in, the local locksmith in Bonn wants to re-asses all its business processes, its branding and store layout with the overall goal of improving workflows, gaining more customers and offer a more selected service range. To do so I programmed and designed them a new website, made suggestions towards a new store concept and layout and provided them with a variety of solutions on their existing problems.",
                },
                {
                    title: "New Website",
                    content: "The main goal of the website was to move away from simply being a virtual business card to actually presenting the team and the work they do. This meant explaining the various services they offer and showcase previous projects they worked on. Additionally two new request forms were incorporated to move customers away from calling them for any regard. ",
                    images: [
                        { image: imgwebsite, subtitle: "Example: Request form"},
                    ],
                },
                {
                    title: "Shop re-design",
                    content: "The current layout shows some flaws as a lot of its space is taken up by products that customers do not buy directly or are not interested in. The new layout should make the room feel more inviting and present products that customers are actually interested in.",
                    images: [
                        { image: img44, subtitle: "Render: Layout store"},
                    ],
                },
                {
                    title: "Problems + Solutions",
                    content: "As I am good friends with one of the employees I had access to a lot of information and spent a lot of time understanding the business, its workflows and its fields of improvement. ",
                    images: [
                        { image: imgSlide1, subtitle: "Slide 1: Cover"},
                        { image: imgSlide2, subtitle: "Slide 2: Agenda"},
                        { image: imgSlide3, subtitle: "Slide 3: Description"},
                        { image: imgSlide4, subtitle: "Slide 4: Cover Problems"},
                        { image: imgSlide5, subtitle: "Slide 5: Problem 1"},
                        { image: imgSlide6, subtitle: "Slide 6: Problem 2"},
                        { image: imgSlide7, subtitle: "Slide 7: Problem 3"},
                        { image: imgSlide8, subtitle: "Slide 8: Problem 4"},
                        { image: imgSlide9, subtitle: "Slide 9: Problem 5"},
                        { image: imgSlide10, subtitle: "Slide 10: Problem 6"},
                        { image: imgSlide11, subtitle: "Slide 11: Cover further ideas"},
                        { image: imgSlide12, subtitle: "Slide 12: Meeting idea"},
                        { image: imgSlide13, subtitle: "Slide 13: Conclusion"},
                    ],
                },
                {
                    title: "Downloads",
                    elements: [
                        {
                            name: "Consulting Pitch",
                            link: consultingPitch,
                            icon: faArrowDown,
                        },
                    ],
                },
            ]
        },
        {
            id: "viktoriabad",
            title_short: "Viktoriabad",
            title_long: "Concept to re-use an abandoned swimming pool",
            backgroundIMG: img39,
            sections: [
                {
                    title: "Overview",
                    content: "The Viktoriabad is an abandoned indoor swimming pool in the center of Bonn. The special facade which consists of drawings of geysers on tainted glass gives the bath a unqiue feel. For the last 10 years the pool has been vacant and has only temporarily been used for minor exhibitions and projects. It is my idea to re-use the space for something the city is really lacking: A place for young people to come together, broaden their cultural horizon and a club.",
                },
                {
                    title: "Current Look",
                    images: [
                        { image: img32, subtitle: "Main Swimming Hall 1/2"},
                        { image: img31, subtitle: "Main Swimming Hall 2/2"},
                        { image: img33, subtitle: "Back view of the learners pool"},
                        { image: img34, subtitle: "Side view of the learners pool"},
                        { image: img35, subtitle: "Front view of the learners pool"},
                        { image: img36, subtitle: "View from learners pool to main hall"},
                        { image: img37, subtitle: "Changing Room entry"},
                        { image: img38, subtitle: "Changing Rooms"},
                    ],
                },
                {
                    title: "Why Nightlife",
                    content: "As Bonn used to be the capital of Germany it has a comparatively good infrastructure. It furthermore hosts major companies (Telekom, DHL, Haribo ...), political ministries and a well reputed university. Nevertheless the cultural offerings especially for younger people is quite poor. In the last 20 years only a handfull of gastronomy or entertainment places opened it doors. On the opposite more and more restaurants and bars are closing down. The city only accomodates 2 Nightclubs of poor quality for its 330.000 inhabitants. The pool is located exactly opposite of the university which is perfect. I believe that the city needs a place where young people and students can come together as well as a modern nightclub.",
                },
                {
                    title: "Concept of the Bar",
                    content: "The core idea is to use the main pool as a big cafe, bar and restaurant. During the day the main pool should be a 'cafe' that attracts people to come study, have lunch or simply drink something. In the evening the space should change towards a 'bar'. Meanwhile as the club is right next door and roughly visible through tainted milky glass windows, it should incentivize people to continue their night there. During the week the evening programm of the bar should offer a variety of cultural opportunites such as poetry slams, stand up comedy, movie nights, listening partys, panel talks or communal drawing. Additionally on sundays the space can be used to host a brunch buffet.",
                },
                {
                    images: [
                        { image: imgProgramm, subtitle: "Examplatory Programm Overview"},
                    ],
                },
                {
                    title: "Concept of the Club",
                    content: "The club and concert space should feel intimate. Similarly like music videos do, the aim is to combine audio and visual aspects. Therefore the whole room will feature a 270 degree LED panel that goes around the room. The club will be open only on friday and saturday night but can be used beforehand and during the week for concerts as well. Both the bar and the club will share the same kitchen and staff facilities which makes it cheaper and more flexible for staff to help out, share supplies and offer specialites like finger food in the club. Using its unique features, the dj booth will also be located in the pool which allows people to stand 360 degree around the DJ.",
                },
                {
                    title: "Renderings",
                    images: [
                        { image: img39, subtitle: "Examplatory Renders: The Club"},
                        { image: imgBar, subtitle: "Examplatory Renders: The Bar"},
                        { image: imgOffice, subtitle: "Examplatory Renders: The Office"},
                    ],
                },
                {
                    title: "Moving forward",
                    content: "In an attempt to bring this project to life, I spoke to one of the members of a NGO that also tries to maintain the swimming pool and save it from getting torn down. He explained to me that the city is the current owner of the building and will evenually hand it over over to the region of North-Rhine Westphalia at the end of 2025. Their plan is to build a 'place of thought' which will probably a library. I am currently trying to get in contact with the city Bonn to gain more informations. Furthermore I got in contact with an architecture firm that specialises in these kind of projects and that happily aggreed to execute my ideas.",
                },
                {
                    title: "Links",
                    elements: [
                        {
                            name: "Rendered Images",
                            link: renders,
                            icon: faArrowDown,
                        },
                    ],
                },
            ]
        },
        {
            id: "oepnv-aachen",
            title_short: "Aachen ÖPNV",
            title_long: "Creation of a more informative Transportation Display",
            backgroundIMG: img40A,
            sections: [
                {
                    title: "Overview",
                    content: "As I was visiting a friend in Aachen, I realised that the digital information screens on the public transport were very old and did not show any information. In an attempt to use the available information each bus has (time until next stop, connections at the stop ...) I redesigned the screen to be more informative while remaining simple.",
                },
                {
                    title: "Inspiration & first sketches",
                    images: [
                        { image: img43A, subtitle: "Current Info Display"},
                        { image: img42A, subtitle: "Inspiration of other info displays"},
                        { image: img41A, subtitle: "First sketches on layout"},
                    ],
                },
                {
                    title: "Design Process",
                    content: "At first I looked at all the different information displays that exist in the public transportation systems all over Germany. Apart from the Deutsche Bahn, most of them looked out of date and provided me rather with hints of 'what not to do'. I then brainstormed what informations a person using a form of public transportation would like to know (e.g. current time, time until stop, next stops, time until next connection at the stop etc.). Knowing what I wanted to display, I moved the information around to remain logical. In essence I created 3 screens: The first one shows general information until the next stop, the second one shows information about connections at the next stop and the third one gives a rough overview where the bus is and where it will go.",
                },
                {
                    title: "Designs explained",
                    content: "When it comes to the information that is displayed, they can all be ranked in priority. Most importantly you want to check if you are on the right bus (info: Bus number and destination), secondly you want to know what the next station(s) is/are. After that one can display the approximate time until the station and already give a hint about accessiblity (e.g. wheel chair friendly) or other forms of public transportation that are available there. Based on that knowledge I emphesized more important information by putting them in boxes or used a bigger and heavier font.",
                },
                {
                    title: "New Designs",
                    images: [
                        { image: img40, subtitle: "Information while driving"},
                        { image: img41, subtitle: "Visual feedback after 'Stop' was pressed"},
                        { image: img42, subtitle: "Information when approaching the station"},
                        { image: img43, subtitle: "Information about rough location on route"},
                    ],
                },
                {
                    title: "Further ideas",
                    content: "As Aachen is located close to the Belgium, many people speak different languages in the city. Therefore my first idea was to make texts like 'Next stop' frequently change between French, English and German.",
                },
            ]
        },
    ];

    return (
        <DataContext.Provider value={{ projects }}>
            {children}
        </DataContext.Provider>
    );
};

// Custom hook to use the context
export const useData = () => {
    return useContext(DataContext);
};
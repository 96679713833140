import React from "react";
import "./home.css";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useData } from "../contexts/datacontext";
import { Helmet } from "react-helmet";

export default function Projects() {

  const { projects } = useData();

  return (
    
    <div className="projects-main">
        <Helmet>
          <title>Projects</title>
        </Helmet>
        <div className="flex-col items-center">
            <div className="massive font-semibold">Personal projects</div>
        </div>
        <div className="flex-col gap-3 items-center" style={{width: "100%"}}>
          {projects.map((project) => (
            <Link to={`/project/${project.id}`} className="alternative" style={{ '--background-img': `url(${project.backgroundIMG})` }} key={project.id}>
              <div>
                <div className="project-title">{project.title_short}</div>
                <div className="small">{project.title_long}</div>
              </div>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          ))}
        <div>+ this website</div>
        </div>
    </div>
  );
}
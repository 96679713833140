import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";

import "../pages/project.css";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const renderDots = () => {
    if (isMobile) {
      const isFirstImage = currentIndex === 0;
      const isLastImage = currentIndex === images.length - 1;
  
      return (
        <>
          {/* First dot for the first image */}
          <span
            className={`dot ${isFirstImage ? "active" : ""}`}
            onClick={() => handleDotClick(0)}
          ></span>
  
          {/* Middle dot for any image between first and last */}
          <span
            className={`dot ${!isFirstImage && !isLastImage ? "active" : ""}`}
            onClick={() => handleDotClick(currentIndex)}
          ></span>
  
          {/* Last dot for the last image */}
          <span
            className={`dot ${isLastImage ? "active" : ""}`}
            onClick={() => handleDotClick(images.length - 1)}
          ></span>
        </>
      );
    } else {
      // Show all dots on larger screens
      return images.map((_, index) => (
        <span
          key={index}
          className={`dot ${index === currentIndex ? "active" : ""}`}
          onClick={() => handleDotClick(index)}
        ></span>
      ));
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePrevClick,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  // Function to open modal with the selected image
  const openModal = () => {
    setSelectedImage(images[currentIndex].image);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="main-box-image-slider" {...handlers}>
      <div className="image-slider">
        <div className="slider-container">
          {images.length > 1 && (
            <button onClick={handlePrevClick} className="slider-button" id="left-button">
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          )}
          <div className="slider-wrapper">
            <img
              src={images[currentIndex]?.image}
              alt="project-image"
              onClick={openModal} // Open modal on image click
              className="bigImageProject"
            />
          </div>
          {images.length > 1 && (
            <div className="dots-container">
              {renderDots()}
            </div>
          )}
          {images.length > 1 && (
            <button onClick={handleNextClick} className="slider-button" id="right-button">
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          )}
        </div>
      </div>
      <div>{images[currentIndex]?.subtitle}</div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={selectedImage} />
    </div>
  );
};

export default ImageSlider;
import React, { useState } from "react";
import "./home.css";
import "./curriculum_vitae.css";
import "../index.css";

import deutschland from "../images/icons/deutschland.png";
import grossbritanien from "../images/icons/grossbritannien.png";
import spanien from "../images/icons/spanien.png";
import frankreich from "../images/icons/frankreich.png";
import css from "../images/icons/css-3.png";
import python from "../images/icons/python.png";
import javascript from "../images/icons/javascript.png";
import figma from "../images/icons/figma.png";
import excel from "../images/icons/excel.png";
import powerpoint from "../images/icons/power-point.png";
import word from "../images/icons/wort.png";
import adobe from "../images/icons/kreative-wolke.png";
import blender from "../images/icons/blender.png";
import niclas from "../images/niclas_website2.jpg";
import { Helmet } from "react-helmet";

export default function CV() {

  const [selectedTimelineSwitcher, setSelectedTimelineSwitcher] = useState("All");
  const [selectedSkillSwitcher, setSelectedSkillSwitcher] = useState("All");

  const handleTimelineSwitcherClick = (category) => {
    setSelectedTimelineSwitcher(category);
  };

  const handleSkillSwitcherClick = (category) => {
    setSelectedSkillSwitcher(category);
  };

  const timelineContent = [
    { category: "Start-Up", date: "01/2020 - NOW", title: "ELGIO", description: "ELGIO is an event plattform that is creating digital products and services to improve the browsing process for users and the managing process for event organizers. We are working with organizers to offer them services in marketing, ticketing, statistical analysis and networking." },
    { category: "Extra Curricular", date: "09/2024 - NOW", title: "Volunteer teacher", description: "My aim is to help 16-25 year olds with completing their basic Highschool diploma and in general supporting them to build self-esteem and having a positive outlook on their future." },
    { category: "Work Experience", date: "07/2022 - 10/2023", title: "Business Operations Assistant at Reference Studios", description: "I was responsable for all operational, accounting, bookkeeping and business tasks the Creative-PR agency was facing. This encapsulated invoicing, drafting proposals, drafting contracts and proposing ideas for better workflows." },
    { category: "Extra Curricular", date: "10/2022", title: "Winner Accenture Case Challenge", description: "Competing, pitching and winning a 1-day case-study challenge on developing a marketing plan for an NGO." },
    { category: "Education", date: "09/2020 - 05/2023", title: "Bachelor of Science in Management at ESCP Business School", description: "The Bachelor focused on experiencing the cultural diversity of Europe while studying fundamental economics as well as soft & management skills. Therefore I lived in London, Madrid, Vienna and Berlin." },
    { category: "Extra Curricular", date: "06/2020", title: "Winner of the 2020 ESCP Scholarship Contest", description: "Within 5 days I had to develop a concept on how to move financial markets towards being more sustainable. My idea was to create a 3-Stage model in which governments intervened as little as possible and companies were incentivising or punishing if they met goals." },    
    { category: "Work Experience", date: "10/2019 - 01/2020", title: "Internship at Hype Sports Innovation", description: "I worked in the marketing department with the goal to attract new startups to the companys accelerator programm. Furthermore I helped represent the company on fairs or work with clients like 1.FC Köln." },
    { category: "Work Experience", date: "09/2019 - 12/2019", title: "Internship at PP&C Business & Political Strategy Consulting", description: "As assistant to the CEO my tasks were wide-spread. They consisted of day-to-day tasks such as meetings, reports etc. as well as help set-up, organise and execute multi-day events with Axel Springer. In addition I implemented new software to improve the companys workflows." },
    { category: "Start-Up", date: "05/2019 - 10/2019", title: "AfroNova and Elionter", description: "After finishing Highschool my aim was to gain experience in the startup world in. I then joined two startups of friends that were focusing on developing and hosting websites for small business owners as a subscription model and creating african fashion." },
    { category: "Extra Curricular", date: "07/2018 - 07/2019", title: "Bonn Winner of Business@School initiative", description: "The BCG intiative was aiming to help students learn about economics by analyzing in-depth listed as well as unlisted companies and giving them consulting advice. Having written the best Germany-wide analysis of the “Otto Group” gave us the chance to meet with Tarek Müller, CEO of About You and present him our ideas." },
    { category: "Exchange", date: "04/2017 - 04/2018", title: "Israel - Germany Friendship Program", description: "An exchange intiative to strengthen the relationship between Israel and Germany. After weeks of preparation and learning, together with our exchange students we visited many cultural and historical sites in Bonn, Berlin, Tel Aviv, Jerusalem and Nazareth." },
    { category: "Work Experience", date: "01/2017 - 02/2017", title: "Internship at Startplatz", description: "Working in a german incubator and co-working space for start-ups. During my time I made it my personal task to interview each start-up in order to better understand what it is like running / working in a startup." },
    { category: "Education", date: "06/2011 - 06/2019", title: "Abitur & CertiLingua at Friedrich-Ebert-Gymnasium", description: "Completing my highschool studies with an overall grade of 1.6 while also attaining the CertiLingua Diploma for finishing highschool with 2 foreign languages." },
    { category: "Exchange", date: "01/2016 - 10/2016", title: "Exchange Year in NZ - Highschool abroad", description: "At 14 I had the opportunity to attend Highschool in New Zealand for 10 Months. During that time I lived with a host family, continued playing competitive hockey and tried various new subjects and sports." },
    { category: "Extra Curricular", date: "2014 - 2017", title: "Selected Hockey Player for NRW", description: "For 4 years I was playing as a selected field hockey player representing the region North Rhine-Westphalia which was highest league at the time. Many of my characteristics and work ethics stem from that time." },
    { category: "Exchange", date: "2015", title: "School Exchange - Meudon, France", description: "One week school exchange to Meudon (near Paris)." },
    { category: "Exchange", date: "2014", title: "School Exchange - Thame, England", description: "Two week school exchange to Thame, Oxford and London." },
  ];

  const skillContent = [
    { category: "Language", date: "Mother Tongue", title: "German", icon: deutschland },
    { category: "Language", date: "Fluent / High proficiency", title: "English", icon: grossbritanien },
    { category: "Language", date: "Solid Knowledge (B1)", title: "Spanish", icon: spanien},
    { category: "Language", date: "School Knowledge (A2)", title: "French", icon: frankreich },

    { category: "Programming", date: "Professional, 1+ years experience", title: "HTML & CSS", icon: css},
    { category: "Programming", date: "Basic, 1 Semester Computer Science", title: "Python", icon: python},
    { category: "Programming", date: "Solid Knowledge, 1+ years experience", title: "JavaScript", icon: javascript},

    { category: "Software", date: "Professional, 4+ years experience", title: "Figma", icon: figma},
    { category: "Software", date: "Professional, 7+ years experience, especially: Ai, Xd, Pr, Ps, Ae, Lr", title: "Adobe", icon: adobe},
    { category: "Software", date: "Professional, 7+ years experience, 1 Semester Computer Skills", title: "Excel", icon: excel},
    { category: "Software", date: "Professional, 8+ years experience, 1 Semester Computer Skills", title: "Powerpoint", icon: powerpoint},
    { category: "Software", date: "Professional, 8+ years experience, 1 Semester Computer Skills", title: "Word", icon: word},
    { category: "Software", date: "Solid Knowledge, see renderings in projects", title: "Blender", icon: blender},
  ];

  return (
    <div className="cv-main">
      <Helmet>
        <title>CV - Niclas</title>
      </Helmet>
      <div className="cv-info-row">
        <img className="cv-image" src={niclas} alt="niclas"/>
        <div className="personal-infos">
          <div className="personal-col">
            <div className="personal-title">Name</div>
            <div className="personal-info">Niclas Maximilian Reif</div>
          </div>
          {/* <div className="personal-col">
            <div className="personal-title">Address</div>
            <div className="personal-info">Lahnstraße 31, 51105 Cologne</div>
          </div> */}
          <div className="personal-col">
            <div className="personal-title">Date and Place of Birth</div>
            <div className="personal-info">04.03.2001, Munich</div>
          </div>
          <div className="personal-col">
            <div className="personal-title">Nationality</div>
            <div className="personal-info">German / Austrian</div>
          </div>
        </div>
      </div>
      
      {/* Timeline Section */}
      <div className="cv-section">
        <div className="cv-subsection">
          <div className="cv-section-header">
            <div className="subsection-header">Timeline</div>
            <div className="timeline-switcher">
              {["All", "Work Experience", "Education", "Start-Up", "Exchange", "Extra Curricular"].map((category) => (
                <div
                  key={category}
                  className={`timeline-switcher-element ${selectedTimelineSwitcher === category ? "active" : ""}`}
                  onClick={() => handleTimelineSwitcherClick(category)}
                >
                  {category}
                </div>
              ))}
            </div>
          </div>
          <div className="timeline-content">
            {timelineContent
              .filter((item) => selectedTimelineSwitcher === "All" || item.category === selectedTimelineSwitcher)
              .map((item, index) => (
                <div key={index} className="timeline-content-element">
                  <div className="timeline-content-date">
                    <div className="flex-row gap-1">
                      <div>●</div>
                      <div>{item.date}</div>
                    </div>
                  </div>
                  <div className="timeline-content-info">
                    <div className="small green">{item.category}</div>
                    <div className="large font-semibold">{item.title}</div>
                    <div className="medium lightgrey">{item.description}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        {/* Skills Section */}
        <div className="cv-subsection">
          <div className="cv-section-header">
            <div className="subsection-header">Skills</div>
            <div className="timeline-switcher">
              {["All", "Language", "Software", "Programming"].map((skill) => (
                <div
                  key={skill}
                  className={`timeline-switcher-element ${selectedSkillSwitcher === skill ? "active" : ""}`}
                  onClick={() => handleSkillSwitcherClick(skill)}
                >
                  {skill}
                </div>
              ))}
            </div>
          </div>
          <div className="timeline-content">
            {skillContent
              .filter((item) => selectedSkillSwitcher === "All" || item.category === selectedSkillSwitcher)
              .map((item, index) => (
                <div key={index} className="timeline-content-element">
                  <div className="timeline-content-skill">
                    <div className="timeline-skill-left">
                      <img 
                        src={item.icon} 
                        alt={item.date} 
                        className={`box ${item.category === "Language" ? "flags" : ""}`} 
                      />
                      <div className="font-semibold">{item.title}</div>
                    </div>
                  </div>
                  <div className="timeline-content-info">
                    <div className="small green">{item.category}</div>
                    <div className="large">{item.date}</div>
                    <div className="medium lightgrey">{item.description}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}
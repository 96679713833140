import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.css";
import { Link } from "react-router-dom";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  return (
    <div className="footer">
        <div className="legal-row">
            <div className="staple">programmed + designed by Niclas</div>
            <div className="flex-row gap-3">
              <Link to={"/imprint"}>
                <FontAwesomeIcon icon={faCircleInfo} />
              </Link>
            </div>
        </div>
    </div>
  );
}
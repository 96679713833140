import { Helmet } from "react-helmet";

export default function Contact() {

    return(
        <div className="contact-main">
            <Helmet>
                <title>Contact</title>
            </Helmet>
            <a href="mailto:niclas.reif@gmail.com">niclas.reif@gmail.com</a>
            <a href="tel:+4915119488634">+49 151 194 88 634</a>
        </div>
    )
}
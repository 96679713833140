import { Helmet } from "react-helmet";

export default function About() {

    return(
        <div className="about-main">
            <Helmet>
                <title>About Niclas</title>
            </Helmet>
            <div className="paragraph">My name is Niclas Reif, I am 23 years old, and I grew up in a family of six in Bonn, a city in western Germany. I spent most of my youth outdoors playing sports, especially field hockey. At 12, I was selected to play for the top regional team in the country for the next four years. Much of my work ethic, teamwork, and leadership skills were formed during this time. When it came time to choose my high school, I opted for one that was less popular among my friends but focused on languages, exchange programs, and other extracurricular activities—a pivotal choice.</div>
            <div className="paragraph">At 14, I had the unique opportunity to attend high school in New Zealand for 10 months. That time was among the best in my life. I tried various new subjects and sports and continued playing competitive hockey for Auckland. After returning to Germany, I enrolled in a government-supported school exchange with an Israeli high school. During my final two years, I also participated in a BCG initiative aimed at introducing students to the world of economics through tasks like analyzing a DAX company, founding a product, and offering consulting advice. This experience inspired me to apply to ESCP, a European business school, a year later.</div>
            <div className="paragraph">To gain further experience in startups and economics, I joined two startups and completed two internships in Tel Aviv before starting my studies. During this time, I developed the idea for ELGIO, an event platform that offers services and products to help people browse and organize events. Ever since, I have been working on ELGIO and have taught myself all aspects of startups.</div>
            <div className="paragraph">Through ESCP's unique program, I had the chance to study economics and management while living in various countries, which reinforced my passion for international environments. In my final year, I also joined a Berlin-based PR consulting agency, where I handled accounting and operations alongside a coworker.</div>
            <div className="paragraph">In recent years, I’ve learned that "creating" and "improving" are what I enjoy most—transforming an idea or problem into a product, sketching, designing, and programming it until it reaches the customer. When I see something that isn’t working properly, I’m immediately flooded with ideas on how to improve or fix it, whether it’s the design of a subway ad, the customer flow in a supermarket, or a company’s business model.</div>
            <div className="paragraph">I would describe myself as a curious, creative, and energetic person. I have an innate curiosity that drives me to understand and learn about everything, which is why I love talking to people I meet. I’m inspired by people who are very different from me, especially those who are deeply passionate about what they do. As Howard Thurman once said, "Don't ask what the world needs. Ask what makes you come alive, and go do it. Because what the world needs is people who have come alive."</div>
        </div>
    );
}
import React from "react";
import "./home.css";
import "./project.css";

import ImageSlider from "../widgets/imageSlider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useData } from "../contexts/datacontext";
import { Helmet } from "react-helmet";


export default function Project() {

  const { id } = useParams();
  const { projects } = useData();

  const projectId = id;

  const project = projects.find(proj => proj.id === projectId);


  return (
    <div className="project-main">
      <Helmet>
        <title>Project - {project.title_short}</title>
      </Helmet>
      <div className="project-container">
        <img src={project.backgroundIMG} alt={project.title_short} className="bg-project" />
        <div className="text-overlay">
        </div>
        <div className="text-project-title">
          <div className="pr-title">{project.title_long}</div>
        </div>
      </div>
        <div className="content-project">
          <div className="project-info-content">
            {project.sections.map((section, index) => (
              <div className="project-section" key={index}>
                {section.title && <div className="pr-section-title">{section.title}</div>}
                {section.content && <div className="large">{section.content}</div>}
                {section.images && <ImageSlider images={section.images} />}
                {section.elements && <div className="flex-col gap-1" style={{ marginTop: "10px" }}>
                  {section.elements.map((element, index) => (
                    <a
                      key={index}
                      className="download-item"
                      href={element.link}
                      download={element.name.endsWith('.pdf') ? element.name : undefined}
                      target={element.link.startsWith('http') ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={element.icon} />
                      <div>{element.name}</div>
                    </a>
                  ))}
                </div> }
              </div>
            ))}
          </div>
        </div>
    </div>
  );
}